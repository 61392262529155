import { Component, Input, OnInit } from '@angular/core';
import { EntityDefinition, FieldDefinition} from 'app/models/entities.model';

import { BaseComponent } from 'app/common/components/base.component';
import { ReportDef } from 'app/models/report.model';
import { TranslateService } from '@ngx-translate/core';


var $primary = "#1c8da8",
	$success = "#40C057",
	$info = "#2F8BE6",
	$warning = "#F77E17",
	$danger = "#F55252",
	$label_color_light = "#E6EAEE";
var themeColors = [$primary, $warning, $success, $danger, $info];

@Component({
	selector: 'fields-correlation-graph',
	templateUrl: './fields-correlation-graph.component.html'
})
export class FieldsCorrelationComponent extends BaseComponent implements OnInit {
	@Input() entities: any[];
	@Input() baseField: FieldDefinition;
	@Input() correlatedField: FieldDefinition;

	columnChartOptions;
	constructor(private _translateService: TranslateService) {
		super();
	}
	ngOnInit(): void {
		let series = [];
		let mapValueToIndex : any = {};
		
		let categories = this.baseField.possibleValues.map((label) => {
			return (label.length > 30)? label.substring(0,30)+"...": label;
		});
		
		this.baseField.possibleValues.forEach((v, index) => {
			mapValueToIndex[""+v] = index;
		})
		console.log("mapValueToIndex",mapValueToIndex);
		
		this.correlatedField.possibleValues.forEach((value) => {
			let serie = {
				name: value,//.substring(0,20)+"...",
				data: []
			}
			for (let i = 0; i < categories.length; i++) {
				serie.data.push(0);
			}

			this.entities.forEach((obj) => {
				if (obj[""+this.correlatedField.fieldLabel] == value) {
					serie.data[mapValueToIndex[obj[this.baseField.fieldLabel]]]++;
				}
			})
			series.push(serie);
		})

		let max = 1;
		series.forEach((s) => {
			s.data.forEach((d) => {
				if (d > max) max = d;
			})
		});
		console.log("MAX: " + max);
		let stepSize =  (Math.trunc(max/10) > 0)? Math.trunc(max/10): 1;

		this.columnChartOptions = {
			chart: {
			  height: 350,
			  type: 'bar',
			},
			colors: themeColors,
			plotOptions: {
			  bar: {
				horizontal: false,
				columnWidth: '55%',
			  },
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
			  show: true,
			  width: 2,
			  colors: ['transparent']
			},
			series: series,
			legend: {
			//   offsetY: -10,
			position: 'top'
			},
			xaxis: {
			  categories: categories
			},
			yaxis: {
				stepSize: stepSize,
			  title: {
				text: ''
			  }
			},
			fill: {
			  opacity: 1
			},
			tooltip: {
			//   y: {
			// 	formatter: function (val) {
			// 	  return "$" + val + " thousands"
			// 	}
			//   }
			}
		  }

	}

}