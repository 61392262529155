import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AdminRoutes } from './pages/admin/admin.module';
import { CompanyRoutes } from './pages/company/company.module';
import { EntityManagementRoutes } from './pages/entity-management/entity-management.module';
import { UnautorizedPage } from './pages/unautorized-page/unautorized-page.page';
import { UnauthorizedPageRoutes } from './pages/unautorized-page/unautorized.module';
import { UserProfileRoutes } from './pages/user-profile/user-profile.module';
import { LandingPage } from './pages/landing-page/landing-page.page';
import { LandingPageRoutes } from './pages/landing-page/landing-page.module';
import { environment } from 'environments/environment';
import { abstractEntityManagementRoutes } from './pages/abstract-entity-management/abstract-entity-management.module';
import { LandingPageV3Routes } from './pages/landing-page-v3/landing-page-v3.module';
import { EntityObjectProviderPage } from './pages/entity-object-provider/entity-object-provider.component';
import { EntityObjectProviderHomePage } from './pages/entity-object-provider/entity-object-provider-home.component';

// const appRoutes: Routes = [
// 	{
//         path: '',
//         redirectTo: "user-profile",
//         pathMatch: 'full',
//     },
//     // { path: '',  pathMatch: 'full', component: AppComponent, data: { title: 'full Views' } },
// 	{
//         path: 'landing',
// 		component: LandingPage
//     },
//     {
//         path: '**',
//         redirectTo: 'pages/error'
//     },
// 	{
// 		path: 'unautorized-page',
// 		component: UnautorizedPage
// 	}
// ];

const appRoutes = environment.appRoutes.map((element) => {
	if (element.path == 'landing') {
		return {
			path: 'landing',
			component: LandingPage
		}
	}
	if (element.path == 'unautorized-page') {
		return {
			path: 'unautorized-page',
			component: UnautorizedPage
		}
	}
	if ((""+element.path).startsWith('data-provider/:')) {
		return {
			path: 'data-provider',
			component: EntityObjectProviderPage
		}
	}
	if ((""+element.path).startsWith('data-provider')) {
		return {
			path: 'data-provider',
			component: EntityObjectProviderHomePage
		}
	}
	return element;
})

@NgModule({
    imports: [
		RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' }),
		RouterModule.forRoot(abstractEntityManagementRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' }),
		// RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		
		RouterModule.forRoot(UserProfileRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' }),
		RouterModule.forRoot(CompanyRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),
		RouterModule.forRoot(AdminRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),
		RouterModule.forRoot(EntityManagementRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),
		RouterModule.forRoot(UnauthorizedPageRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),
		RouterModule.forRoot(LandingPageRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),
		RouterModule.forRoot(LandingPageV3Routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy',anchorScrolling: 'enabled' }),

	],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
