import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";

@Component({
    selector: 'entity-object-provider-home',
    templateUrl: './entity-object-provider-home.component.html'
})
export class EntityObjectProviderHomePage implements OnInit {
	logo = environment.domainConfig.landingPageSiteLogo;
	constructor(
		
	) {

	}

	
	ngOnInit(): void {
		
	}

	
}