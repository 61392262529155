export class ResponseScore {
	responseValue: any;
	score: number;
}
export class FieldScoreDef {
	fieldId: any;
	scores: ResponseScore[];
}

export class Correlation {
	label: string;
	firstFieldId: any;
	lastFieldId: any;
}
export class ReportDef {
	id: any;
	entityId: any;
	lastUpdateDate: any;
	fieldsScore: FieldScoreDef[] = [];
	correlations: Correlation[] = [];
}