import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iconAdd, iconCert, iconExcel, iconQRCode, iconSave } from 'app/common/utils/icons.utils';
import { EntityDefinition, FieldDefinition, FieldType } from 'app/models/entities.model';

import { ProfileService } from 'app/services/profile.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { EntityConfigurationService } from 'app/services/entity-configuration.service';
import { StoredObjectService } from 'app/services/stored-object.service';
import { Location } from '@angular/common';
import { BaseComponent } from 'app/common/components/base.component';
import { ModalService } from 'app/common/services/modal.service';
import { DataReloader } from '../../components/list-object-preview/list-object-preview.component';
import { forkJoin } from 'rxjs';
import { ACR, ACRService, ARCPermission } from 'app/services/acr.service';
import { ReportService } from 'app/services/report.service';
import { ReportDef } from 'app/models/report.model';
import { TranslateService } from '@ngx-translate/core';


var $primary = "#1c8da8",
	$success = "#40C057",
	$info = "#2F8BE6",
	$warning = "#F77E17",
	$danger = "#F55252",
	$label_color_light = "#E6EAEE";
var themeColors = [$primary, $warning, $success, $danger, $info];

@Component({
	selector: 'report-dashboard-page',
	templateUrl: './report-dashboard.page.html',
	styleUrls: ['report-dashboard.page.scss']
})
export class ReportDashboardPage extends BaseComponent implements OnInit {
	userProfile: UserProfile;
	rootAbstractEntityId: EntityDefinition;
	currentEntityDef: EntityDefinition;
	pageLoaded = false;
	activeTab = "baseEntityDetails";
	valid = false;
	objectIstance: any = null;
	parentIdChain;
	idWhitParent: any;
	historypath: any[] = [];

	iconSave = iconSave;
	iconCertify = iconCert;
	iconAdd = iconAdd;
	iconExcel = iconExcel;
	iconQr = iconQRCode;
	listObjectDataReloaders: any = {};
	userACRs: ACR[] = [];

	canEdit: boolean = false;
	reportConfig: ReportDef;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _translateService: TranslateService,
		private _userProfileService: ProfileService,
		private _entityConfigurationService: EntityConfigurationService,
		private _storedObjectService: StoredObjectService,
		private _reportService: ReportService
	) {
		super();
	}

	
	//entity-home/:rootAbstractEntityId/:abstractEntityId/:parentIdChain/:id

	private searchEntityDefAndUpdateHistoryPath = (entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			this.historypath.unshift({
				abstractEntityId: entityId,
				rootAbstractEntityId: this.rootAbstractEntityId.entityId,
				entityName: entity.name
			})
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.searchEntityDefAndUpdateHistoryPath(children, entityId);
				if (hint) {
					this.historypath.unshift({
						abstractEntityId: entity.entityId,
						entityName: entity.name,
						rootAbstractEntityId: this.rootAbstractEntityId.entityId
					})

					found = hint;
				}
			})
		}
		return found;
	}



	private resetComponentState = () => {
		this.historypath = [];
		// if (this.walletForm) this.walletForm.patchValue({secret: null});
	}
	private entities: any[];

	ngOnInit() {
		console.log("ngOnInit");
		this.resetComponentState();
		this._userProfileService.getLoggedProfile().subscribe((profile) => {
			this.userProfile = profile;
			this.on(
				this._activatedRoute.paramMap.subscribe(qp => {

					if (qp && qp.get("rootAbstractEntityId")) {
						this.resetComponentState();
						forkJoin({
							rootAbstractEntityId: this._entityConfigurationService.getByField("entityId", qp.get("rootAbstractEntityId")),
							// userACRs: this._acrService.getACROfProfile(this.userProfile),

						}).subscribe((result) => {
							// this.userACRs = result.userACRs;
							this.rootAbstractEntityId = result.rootAbstractEntityId;

							// if (this.isAdmin) {
							// 	this.canEdit = true;
							// }
							// else {
							// 	this.userACRs.forEach((acr) => {
							// 		if (acr.entityId == this.rootAbstractEntityId .entityId) {
							// 			if (acr.permission == ARCPermission.EDITOR) this.canEdit = true;
							// 			else this.canEdit = false;
							// 		}
							// 	})
							// }
							if (qp.get("rootAbstractEntityId") == qp.get("abstractEntityId")) {
								this.currentEntityDef = result.rootAbstractEntityId
							}
							else {
								this.currentEntityDef = this.searchEntityDefAndUpdateHistoryPath(this.rootAbstractEntityId, qp.get("abstractEntityId"));
							}
							this.parentIdChain = qp.get("parentIdChain");
							this.idWhitParent = this.parentIdChain + "." + qp.get("id");
							let ids = ("" + this.parentIdChain).split(".");
							this.historypath = this.historypath.map((element, index) => {
								element["parentIdChain"] = ids.slice(0, index + 1).join(".");
								element["objectId"] = ids[index + 1];
								return element;
							})
							console.log("history: ", this.historypath);
							if (this.historypath.length > 0) {
								this.historypath.pop();
							}
							forkJoin({
								storedObjects: this._storedObjectService.getAll(this.currentEntityDef),
								reportConfig: this._reportService.getReportFor(this.currentEntityDef)

							}).subscribe((data: any) => {

								if (this.parentIdChain) {
									this.entities = data.storedObjects.filter((value) => {
										return value.parentId == this.parentIdChain;
									})
								}
								else {
									this.entities = data.storedObjects;
								}
								this.reportConfig = data.reportConfig[0];
								this.initCorrelations();
								this.initScores();
								this.makeGraph();
								this.makePies();
								this.pageLoaded = true;
							})



						})
					}

				})
			);



		})
	}


	barChartOptions = {
		chart: {
			  height: 350,
			type: 'bar',
		},
		colors: themeColors,
		plotOptions: {
			bar: {
				horizontal: true,
			}
		},
		dataLabels: {
			enabled: false
		},
		series: [],
		xaxis: {
			categories: [],
			tickAmount: 6
		}
	}

	/** values and scores */

	totalCount: number = 0;
	meanScore: number = 0;
	maxScore: number = 0;
	minScore: number = 1000000;
	
	

	private scores: any = {};
	correlations = [];
	private initCorrelations = () => {
		if (this.reportConfig && this.reportConfig.correlations) {
			this.reportConfig.correlations.forEach((c) => {
				let toAdd: any = {
					label: c.label
				}
				this.currentEntityDef.baseInfo.forEach((info) => {
					if (info.fieldId == c.firstFieldId) {
						toAdd.first = info
					}
					if (info.fieldId == c.lastFieldId) {
						toAdd.second = info
					}
				})
				this.currentEntityDef.groups.forEach((group) => {
					group.fields.forEach((info) => {
						if (info.fieldId == c.firstFieldId) {
							toAdd.first = info
						}
						if (info.fieldId == c.lastFieldId) {
							toAdd.second = info
						}
					})
				})
				this.correlations.push(toAdd);
			})
		}		
	}
	private initScores = () => {
		if (this.reportConfig) {
			this.reportConfig.fieldsScore.forEach((scd) => {
				scd.scores.forEach((responseScore) => {
					this.scores[scd.fieldId + "." + responseScore.responseValue] = responseScore.score;
				})
			})
		}
		this.totalCount = this.entities.length;
		let sumScore = 0;
		this.entities = this.entities.map((obj) => {
			let partialCount = 0;
			
			this.currentEntityDef.baseInfo.forEach((info) => {
				if (info.fieldType == FieldType.SINGLE_SELECT) {
					partialCount += this.scores[info.fieldId + "." + obj["" + info.fieldLabel]];
				}
			})
			this.currentEntityDef.groups.forEach((group) => {
				group.fields.forEach((info) => {
					if (info.fieldType == FieldType.SINGLE_SELECT) {
						partialCount += this.scores[info.fieldId + "." + obj["" + info.fieldLabel]];
					}
				})
			})
			sumScore += partialCount;
			if (partialCount > this.maxScore) this.maxScore = partialCount;
			if (partialCount < this.minScore) this.minScore = partialCount;
			obj.reportScore = partialCount;
			return obj;
		})
		this.meanScore = sumScore / this.totalCount;
	}


	private makeMeanForQuestion = (fieldDefinition: FieldDefinition): number => {
		let result = 0;
		this.entities.forEach((obj) => {
			result += this.scores[fieldDefinition.fieldId + "." + obj["" + fieldDefinition.fieldLabel]];
		})
		return result / this.entities.length;
	}
	private makeGraph = () => {
		let categories = [];
		let data = [];
		let max = -1;
		

		this.currentEntityDef.baseInfo.forEach((info) => {
			if (info.fieldType == FieldType.SINGLE_SELECT) {
				categories.push(info.fieldLabel);
				let value = this.makeMeanForQuestion(info);
				data.push(value);
				if (value > max) max = value;
			}
		})
		this.currentEntityDef.groups.forEach((group) => {
			group.fields.forEach((info) => {
				if (info.fieldType == FieldType.SINGLE_SELECT) {
					categories.push(info.fieldLabel);
					let value = this.makeMeanForQuestion(info);
					data.push(value);
					if (value > max) max = value;
				}
			})
		})
		this.barChartOptions.xaxis.tickAmount = max;
		this.barChartOptions.series.push({ name: this._translateService.instant("report.averageOfValues"), data: data });
		this.barChartOptions.xaxis.categories = categories;
	}

	private getHistoryPath = (historypath: any[], entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			historypath.unshift({
				abstractEntityId: entityId,
				rootAbstractEntityId: this.rootAbstractEntityId.entityId,
				entityName: entity.name
			})
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.getHistoryPath(historypath, children, entityId);
				if (hint) {
					historypath.unshift({
						abstractEntityId: entity.entityId,
						entityName: entity.name,
						rootAbstractEntityId: this.rootAbstractEntityId.entityId
					})
					found = hint;
				}
			})
		}
		return found;
	}


	pies = [];

	private makeSinglePie = (fieldDef: FieldDefinition) => {
		let customLegendItems = fieldDef.possibleValues.map((label) => {return label.substring(0,40)+"..."});
		let labels = fieldDef.possibleValues.map((label) => {return label.substring(0,40)+"..."});
		let series = [];

		fieldDef.possibleValues.forEach((value) => {
			let count = 0;
			this.entities.forEach((obj) => {
				if (obj["" + fieldDef.fieldLabel] == value) count++
				
			})
			series.push(count);
		})

		

		let pieChartOptions = {
			chart: {
				type: 'pie',
				height: 300
			},
			colors: themeColors,
			labels: labels,
			series: series,
			legend: {
				itemMargin: {
					horizontal: 2
				},
			},
			responsive: [{
				breakpoint: 576,
				options: {
					chart: {
						// width: 300,
						// height: 300
					},
					legend: {
						customLegendItems: customLegendItems,
						position: 'bottom',
						height: 700,
						floating: true
					}
				}
			}]
		}

		let pie: any = {
			label: fieldDef.fieldLabel,
			pieChartOptions: pieChartOptions
		}
		this.pies.push(pie);
	}
	private makePies = () => {
		

		this.currentEntityDef.baseInfo.forEach((info) => {
			if (info.fieldType == FieldType.SINGLE_SELECT) {
				this.makeSinglePie(info);
			}
		})
		this.currentEntityDef.groups.forEach((group) => {
			group.fields.forEach((info) => {
				if (info.fieldType == FieldType.SINGLE_SELECT) {
					this.makeSinglePie(info);
				}
			})
		})

		
	}


	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}



}