import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition } from "app/models/entities.model";
import { EditAbstractEntityModalForm } from "./abstract-entity-editor/edit-abstract-entity.modal";
import { ModalService } from "app/common/services/modal.service";
import { iconAdd, iconChart, iconEdit, iconExcel, iconMoreUsers, iconTrash, iconUserShield } from "app/common/utils/icons.utils";
import { AbstractEntityPermissionModalForm } from "./abstract-entity-permission/abstract-entity-permission.modal";
import { CompanyService_CONFIG_DOMAIN, CompanyService_DOMAINS_CONFIG } from "app/services/entity-configuration.service";
import { ProfileService } from "app/services/profile.service";
import { Roles } from "app/models/profile.models";
import { AbstractEntityLandingPageModalForm } from "./landing-page-editor/abs-landing-page-editor-modal.page";
import { EditEntityReportModalForm } from "../reporting/abstract-entity-editor/edit-entity-report.modal";

@Component({
    selector: 'entity-def-overview',
    templateUrl: './entity-def-overview.component.html',
	styleUrls: ["entity-def-overview.component.scss"]
})
export class AbstractEntityDefOverviewComponent implements OnInit {
	iconEdit = iconEdit;
	iconTrash = iconTrash;
	iconAdd = iconAdd;
	iconUserPermission = iconMoreUsers;
	iconExcel = iconExcel;
	iconUserShield = iconUserShield;
	iconChart = iconChart;
	@Input("entity") entity: EntityDefinition;
	@Input("canUpdate") canUpdate: boolean = false;
	@Input("isRoot") isRoot: boolean = false;
	@Input("rootEntity") rootEntity: EntityDefinition;

	@Output() public onSsaveEntity: EventEmitter<any> = new EventEmitter<any>();
	@Output() public requestDelete: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onCreateSheet: EventEmitter<any> = new EventEmitter<EntityDefinition>();
	@Output() public onEditReport: EventEmitter<any> = new EventEmitter<EntityDefinition>();
	constructor(private _modalService: ModalService, private _userProfileService: ProfileService) {

	}

	public get canModify() {
		return this._userProfileService.isInRole(Roles.ADMIN) || (
			this._userProfileService.isInRole(Roles.ENTITY_ADMIN) && this.canUpdate)
	}
	public get canShareEntity() {
		return this._userProfileService.isInRole(Roles.ADMIN) || this._userProfileService.isInRole(Roles.USER_ADMIN);
	}
	dispatchSaveEvent = ($event) => {
		this.onSsaveEntity.emit($event);
	}

	ngOnInit(): void {
		
	}

	editEntity = () => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			backdrop: 'static',
			keyboard: false,
			callback: (res) => {
				if (res) {
					Object.assign(this.entity, res);
					this.onSsaveEntity.emit(this.entity);
				}
			}
		}
		let data = {
			entity: this.entity,
		}
		this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	}

	public editLP = () => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			callback: (res) => {				
				if (res) {
					this.entity.hasLPDefinition = true;
					this.onSsaveEntity.emit(this.entity);
				}
				
			}
		}
		let data = {
			entity: this.entity,
			rootAbstractEntityId:  this.rootEntity,
			object: null,
			userProfile: null,
			pageId: this.entity.entityId
		}
		this._modalService.show(AbstractEntityLandingPageModalForm, data, modalOption);
	}

	editPermission = () => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					Object.assign(this.entity, res);
					this.onSsaveEntity.emit(this.entity);
				}
			}
		}
		let data = {
			entityDomain: CompanyService_DOMAINS_CONFIG + "/" + CompanyService_CONFIG_DOMAIN,
			entityId:  this.entity.entityId,
			entityName:  this.entity.name

		}
		this._modalService.show(AbstractEntityPermissionModalForm, data, modalOption);
		
	}
	addChild = () => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			backdrop: 'static',
			keyboard: false,
			callback: (res) => {
				if (res) {
					console.log("result: ", res);
					if (!this.entity.entityChilds) this.entity.entityChilds = [];
					res.entityId = this.entity.entityId + "." + res.entityId;
					this.entity.entityChilds.push(res);
					this.onSsaveEntity.emit(this.entity);
				}
			}
		}
		let data = {
			entity: null,
		}
		this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	}

	delete = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.requestDelete.emit(this.entity);
				}
			}
		}
		let data = {
			entity: null,
		}
		this._modalService.showConfirm("Elimina entità","Confermi di eliminare l'entità " + this.entity.name + "?", modalOption);
	}

	onChildRequestDelete = (entity: EntityDefinition) => {
		this.entity.entityChilds = this.entity.entityChilds.filter((child) => { return entity.entityId != child.entityId});
		this.onSsaveEntity.emit(this.entity);
	}
	handleCreateSheet = (entity) => {
		this.onCreateSheet.emit(entity);
	}

	public editReport = () => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {
				// if (res) {
				// 	this.entity.hasLPDefinition = true;
				// 	this.onSsaveEntity.emit(this.entity);
				// }
				console.log(res);
				
			}
		}
		let data = {
			entity: this.entity
		}
		this._modalService.show(EditEntityReportModalForm, data, modalOption);
	}
}