import { Component, OnInit } from '@angular/core';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { EntityDefinition, FieldDefinition, FieldType} from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'excel-object-helper',
    templateUrl: './modal-excel-helper.modal.html',
	styleUrls: ['modal-excel-helper.modal.scss']
})
export class ObjectReaderExcelHelperModal implements OnInit {
	dataLoaded;
	
	iconCancel = iconCancel;
	iconSave = iconSave;
	
	data: {
		currentEntityDef: EntityDefinition
	};

	dataFields = [];
	multipleFields: any[] = [];
	singleFields: any[] = [];

	constructor(
		private activeModal: NgbActiveModal,
		) {
    }

	ngOnInit(): void {
		this.createColumms();
	}

	
	public close = (result): void => {
		this.activeModal.close(result);
    }

	private addColumn = (field: FieldDefinition, columnId, columnName) => {
		if (field.fieldType == FieldType.DATE) {
			this.dataFields.push(columnId)
		}
		else if (field.fieldType == FieldType.MULTIPLE_SELECT) {
			this.multipleFields.push({label: columnId, values: field.possibleValues});
		}
		else if (field.fieldType == FieldType.SINGLE_SELECT) {
			this.singleFields.push({label: columnId, values: field.possibleValues});
		}
	}

    private createColumms = (): void => {
		this.data.currentEntityDef.baseInfo.forEach((entity) => {
			this.addColumn(entity, entity.fieldLabel,entity.fieldLabel);
		})
		this.data.currentEntityDef.groups.forEach((group) => {
			group.fields.forEach((entity) => {
				this.addColumn(entity, group.groupLabel + " - " + entity.fieldLabel,entity.fieldLabel);
			})
			
		})
    }

	

	
}


