import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseComponent } from "app/common/components/base.component";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ToastService } from "app/common/services/toasts.service";
import { iconCancel, iconCert, iconSave, iconTrash } from "app/common/utils/icons.utils";
import { EntityDefinition, FieldType, FieldValidator, ValidatorType } from "app/models/entities.model";
import { UserProfile } from "app/models/profile.models";
import { BlockChainService } from "app/services/blockchain.service";
import { StoredObjectService } from "app/services/stored-object.service";

@Component({
    selector: 'add-new-entity-modal',
    templateUrl: './add-new-entity.modal.html'
})
export class AddNewEntityModalEditor extends BaseComponent implements OnInit {
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconCertify = iconCert;
	dataLoaded = false;
	valid = false;
	public walletForm: FormGroup = null;

	data: {
		profile: UserProfile,
		currentEntityDef: EntityDefinition,
		parentIdChain: any,
		objectIstance: any,
		rootAbstractEntity: EntityDefinition,
		historypath: any
	};

	constructor(private _activeModal: NgbActiveModal, private _fb: FormBuilder,
		private _blockChainService: BlockChainService,
		private _storedObjectService: StoredObjectService,
		private _toastService: ToastService
	) {
		super();
	}
	ngOnInit(): void {
		console.log("data:",this.data);
		
		this.walletForm =this._fb.group({
			secret: [null, [CommonValidators.walletPrivateKeyValidator(this.data.profile.walletInfo.pubKey, this._blockChainService)]]
		});
		this.dataLoaded = true;
	}
	public get canSaveForm(): boolean {
		return false;
	}

	public close = (): void => {
		this._activeModal.close(false);
    }

	onObjectUpdate = (update) => {
		if (!this.data.objectIstance) this.data.objectIstance={};
		Object.assign(this.data.objectIstance, update);
	}
	objectValid = (valid) => {
		this.valid = valid;
	}
	public get canSaveAndCertify(): boolean {
		return this.valid && this.walletForm && this.walletForm.valid;
	}
	saveObject = (toCertify?) => {
		if (this.valid) {
			if (!this.data.objectIstance) this.data.objectIstance={};
			this.data.objectIstance.parentId = this.data.parentIdChain;
			this.data.objectIstance.entitiDefId = this.data.currentEntityDef.entityId;
			this.data.objectIstance.rootEntityDefId = this.data.rootAbstractEntity.entityId;
			this._storedObjectService.saveOrUpdate(this.data.currentEntityDef,this.data.objectIstance).subscribe((newObject) => {
				this.data.objectIstance = newObject;
				this._toastService.showSuccess("I dati sono stati salvati.");
				if (toCertify && this.walletForm && this.walletForm.valid) {
					this._storedObjectService.certify(this.data.objectIstance,this.data.historypath,this.data.rootAbstractEntity,this.data.currentEntityDef,this.data.profile, this.walletForm.value.secret).then((__result) => {
						this._toastService.showSuccess("I dati sono stati salvati certificati.");
						this._activeModal.close(newObject);
					})
				}
				else {
					this._activeModal.close(newObject);
				}
			})
		}
	}
}
