import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UserProfileModule } from '../user-profile/user-profile.module';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CompanyModule } from '../company/company.module';
import { AbstractEntityConfiguratorComponent } from './configurator/configurator.component';
import { EditAbstractEntityModalForm } from './configurator/abstract-entity-editor/edit-abstract-entity.modal';
import { ModalAbstractValidatorEditor } from './configurator/abstract-entity-editor/abs-validator-editor.modal';
import { AbstractGroupDefinitionComponent } from './configurator/abstract-entity-editor/abs-group-definition.component';
import { AbstractEntityDefOverviewComponent } from './configurator/entity-def-overview.component';
import { EntityHomePage } from './entity-navigation/entity-home/entity-home.page';
import { ObjectFormComponent } from './components/object-form/object-form.component';
import { EntityField } from './components/entity-field/entity-field.component';
import { ObjectPreviewComponent } from './components/object-preview/object-preview.component';
import { ListObjectPreviewComponent } from './components/list-object-preview/list-object-preview.component';
import { AbstractEntityPermissionModalForm } from './configurator/abstract-entity-permission/abstract-entity-permission.modal';
import { UserPermissionSelectionComponent } from './configurator/abstract-entity-permission/user-permission-selection.component';
import { AddNewEntityModalEditor } from './entity-navigation/add-entity-modal/add-new-entity.modal';
import { AbstractEntityFieldSelectorModalForm } from './configurator/landing-page-editor/abs-field-selector-modal.page';
import { AbstractEntityLandingPageSectionEditorForm } from './configurator/landing-page-editor/abs-lp-section-editor.form';
import { AbstractEntityLandingPageModalForm } from './configurator/landing-page-editor/abs-landing-page-editor-modal.page';
import { ObjectReaderExcelFileModal } from './components/modal-excel-object-reader/modal-excel-object-reader.modal';
import { ObjectReaderExcelHelperModal } from './components/modal-excel-helper/modal-excel-helper.modal';
import { EditEntityReportModalForm } from './reporting/abstract-entity-editor/edit-entity-report.modal';
import { ReportDashboardPage } from './reporting/reports-dashboard/report-dashboard.page';
import { ChartsModule } from "ng2-charts";
import { NgApexchartsModule } from "ng-apexcharts";
import { EntitiesClusteredByScoreComponent } from './reporting/reports-dashboard/entities-clustered-by-score-range/entities-clustered-by-score-range.component';
import { FieldsCorrelationComponent } from './reporting/reports-dashboard/entity-correlation-graph/fields-correlation-graph.component';

export const abstractEntityManagementRoutes: Routes = [
    { path: 'entity-home/:rootAbstractEntityId/:abstractEntityId/:parentIdChain', component: EntityHomePage, canActivate: []},
    { path: 'entity-home/:rootAbstractEntityId/:abstractEntityId/:parentIdChain/:id', component: EntityHomePage, canActivate: []},
    { path: 'reports/:rootAbstractEntityId/:abstractEntityId', component: ReportDashboardPage, canActivate: []},
	
    // { path: 'entity-home/create/:abstractEntityId', component: EntityHomePage, canActivate: []}
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(abstractEntityManagementRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule,
		NgbModule,
		ChartsModule,
		NgApexchartsModule
    ],
    exports: [
		// EntityField,
		// ModalEntityList,
		// ModalExcelFileReader
		AbstractEntityConfiguratorComponent,
		EditAbstractEntityModalForm,
		ModalAbstractValidatorEditor,
		AbstractGroupDefinitionComponent,
		AbstractEntityDefOverviewComponent,
		ObjectFormComponent,
		ObjectPreviewComponent,
		ListObjectPreviewComponent,
		AbstractEntityPermissionModalForm,
		UserPermissionSelectionComponent,
		AddNewEntityModalEditor,
		AbstractEntityFieldSelectorModalForm,
		AbstractEntityLandingPageSectionEditorForm,
		AbstractEntityLandingPageModalForm,
		ObjectReaderExcelFileModal,
		ObjectReaderExcelHelperModal,
		EditEntityReportModalForm,
		EntitiesClusteredByScoreComponent,
		FieldsCorrelationComponent
	],
    declarations: [
		EntityHomePage,
		AbstractEntityConfiguratorComponent,
		EditAbstractEntityModalForm,
		ModalAbstractValidatorEditor,
		AbstractGroupDefinitionComponent,
		AbstractEntityDefOverviewComponent,
		ObjectFormComponent,
		EntityField,
		ObjectPreviewComponent,
		ListObjectPreviewComponent,
		AbstractEntityPermissionModalForm,
		UserPermissionSelectionComponent,
		AddNewEntityModalEditor,
		AbstractEntityFieldSelectorModalForm,
		AbstractEntityLandingPageSectionEditorForm,
		AbstractEntityLandingPageModalForm,
		ObjectReaderExcelFileModal,
		ObjectReaderExcelHelperModal,
		EditEntityReportModalForm,
		ReportDashboardPage,
		EntitiesClusteredByScoreComponent,
		FieldsCorrelationComponent
		// EntityListPage,
		// EditEntityPage,
		// EntityField,
		// ModalEntityList,
		// ModalExcelFileReader
    ],
    providers: [
		AbstractEntityConfiguratorComponent,
		EditAbstractEntityModalForm,
		ModalAbstractValidatorEditor,
		AbstractGroupDefinitionComponent,
		AbstractEntityDefOverviewComponent,
		EntityField,
		ObjectPreviewComponent,
		ListObjectPreviewComponent,
		AbstractEntityPermissionModalForm,
		UserPermissionSelectionComponent
	],
	entryComponents: [
		// EntityField,
		// ModalEntityList,
		// ModalExcelFileReader
		AbstractEntityConfiguratorComponent,
		EditAbstractEntityModalForm,
		ModalAbstractValidatorEditor,
		AbstractGroupDefinitionComponent,
		AbstractEntityDefOverviewComponent,
		ObjectFormComponent,
		EntityField,
		ObjectPreviewComponent,
		ListObjectPreviewComponent,
		AbstractEntityPermissionModalForm,
		UserPermissionSelectionComponent,
		AddNewEntityModalEditor,
		AbstractEntityFieldSelectorModalForm,
		AbstractEntityLandingPageSectionEditorForm,
		AbstractEntityLandingPageModalForm,
		ObjectReaderExcelFileModal,
		ObjectReaderExcelHelperModal,
		EditEntityReportModalForm,
		EntitiesClusteredByScoreComponent,
		FieldsCorrelationComponent
	]
})
export class AbstractEntityManagementModule { }
