import { Injectable } from "@angular/core";
import { Company, EntityDefinition, FieldType, Product } from "app/models/entities.model";
import { UserProfile } from "app/models/profile.models";
import { environment } from "environments/environment";
import moment from "moment";
import { Observable, of} from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { BlockChainService } from "./blockchain.service";
import { HttpService } from "./http.service";
import { URLUtils } from "./url-utils";
import { SwitchDataLakeService } from "./switch-data-lake.service";
import { HttpErrorResponse } from "@angular/common/http";


@Injectable({
	providedIn: 'root'
})
export class ReportService {
	private domainName = "REPORT_CFG_DEF"
	private baseUrl = environment.services.apiBasePath;
	constructor(private _httpService: HttpService, private _chainService: BlockChainService, private _dataLake: SwitchDataLakeService) {
	}
	private errorHandler(error: HttpErrorResponse) {
			return of(null);
		}
	public getReportFor = (entityDef: EntityDefinition): Observable<any> => {
		let domain =  this.domainName + "/" +entityDef.entityId;
		let url = this.baseUrl + "/" + domain;
		return this._httpService.get(url).pipe(
			catchError(this.errorHandler)
		);
	}
	public deleteReport = (entityDef: EntityDefinition, id: any): Observable<any> => {
		let domain =  this.domainName + "/" +entityDef.entityId;
		let url = this.baseUrl + "/" + domain + "/" + id;
		return this._httpService.delete(url);
	}
	// public getByField = (companyId: string, productId: string, entityId: string, fieldId: string, value: any): Observable<any> => {
	// 	let domain =  companyId + "/" +productId + "." + entityId;
	// 	let url = this.baseUrl + "/" + domain + "/" + fieldId + "/" + value;
	// 	return this._httpService.get(url);
	// }
	// public getAllByField = (companyId: string, productId: string, entityId: string, fieldId: string, value: any): Observable<any> => {
	// 	let domain =  companyId + "/" +productId + "." + entityId;
	// 	let url = this.baseUrl + "/" + domain + "/" + fieldId + "/" + value+"?asList=true";
	// 	return this._httpService.get(url);
	// }

	
	
	// public saveOrUpdate = (entity: any, companyId: string, productId: string, entityId: string, toNotify: boolean = false): Observable<any> => {
	public saveOrUpdate = (entity: any, entityDef: EntityDefinition, toNotify: boolean = false): Observable<any> => {
		// let companyId = company.companyId;
		// let productId = product.productId
		// let entityId = entityDef.entityId;

		let domain =  this.domainName + "/" +entityDef.entityId;
		let url = this.baseUrl + "/" + domain;
		entity.lastUpdateDate = moment().format("YYYY-MM-DD hh:mm:ss");
		let toCall;
		if (entity.id) {
			console.log("execute PUT");
			
			toCall = this._httpService.put(url + "/"+entity.id + "?notify="+toNotify, entity,"json");
		}
		else {
			console.log("execute POST");
			toCall = this._httpService.post(url + "?notify="+toNotify,entity,"json");
		}
		console.log("to save: ", entity);
		
		return toCall;
	}

	

}