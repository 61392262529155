import { Component, Input, OnInit } from '@angular/core';
import { EntityDefinition} from 'app/models/entities.model';

import { BaseComponent } from 'app/common/components/base.component';
import { ReportDef } from 'app/models/report.model';
import { TranslateService } from '@ngx-translate/core';


var $primary = "#1c8da8",
	$success = "#40C057",
	$info = "#2F8BE6",
	$warning = "#F77E17",
	$danger = "#F55252",
	$label_color_light = "#E6EAEE";
var themeColors = [$primary, $warning, $success, $danger, $info];

@Component({
	selector: 'entities-clustered-by-score-range',
	templateUrl: './entities-clustered-by-score-range.component.html'
})
export class EntitiesClusteredByScoreComponent extends BaseComponent implements OnInit {
	currentEntityDef: EntityDefinition;
	listObjectDataReloaders: any = {};
	

	canEdit: boolean = false;
	@Input() reportConfig: ReportDef;
	@Input() scores: any;
	@Input() entities: any[];
	@Input() label: string;

	minPossibleScore: number = 0;
	maxPossibleScore: number = 0;
	pieChartOptions;
	constructor(private _translateService: TranslateService) {
		super();
	}
	ngOnInit(): void {
		if (this.reportConfig) {
			this.reportConfig.fieldsScore.forEach((scd) => {
				let partialMin = 1000000;
				let partialMax = 0;
				scd.scores.forEach((responseScore) => {
					if (responseScore.score < partialMin) partialMin = responseScore.score;
					if (responseScore.score > partialMax) partialMax = responseScore.score;
				})
				this.minPossibleScore += partialMin;
				this.maxPossibleScore += partialMax;
			})
			
		}

		let diff = this.maxPossibleScore - this.minPossibleScore;
		let clusterValue = diff / 5;
		let series = [0,0,0,0,0];
		let label = this._translateService.instant("report.rangeLabel");
		let labels = [
			label+" ["+this.minPossibleScore.toFixed(2) + " - " + (this.minPossibleScore+clusterValue).toFixed(2) + "]",
			
			label+" ["+(this.minPossibleScore+clusterValue).toFixed(2) + " - " + (this.minPossibleScore+clusterValue*2).toFixed(2) + "]",
			label+" ["+(this.minPossibleScore+clusterValue*2).toFixed(2) + " - " + (this.minPossibleScore+clusterValue*3).toFixed(2) + "]",
			label+" ["+(this.minPossibleScore+clusterValue*3).toFixed(2) + " - " + (this.minPossibleScore+clusterValue*4).toFixed(2) + "]",
			label+" ["+(this.minPossibleScore+clusterValue*4).toFixed(2) + " - " + (this.maxPossibleScore).toFixed(2) + "]"
		]
		this.entities.forEach((obj) => {
			for (let i=4; i >= 0; i--) {
				if (obj.reportScore >= (this.minPossibleScore + (clusterValue*i))) {
					series[i]++;
					i=-1;
				}
			}
		})


		
		this.pieChartOptions = {
			chart: {
				type: 'pie',
				height: 300
			},
			colors: themeColors,
			labels: labels,
			series: series,
			legend: {
				itemMargin: {
					horizontal: 2
				},
			},
			responsive: [{
				breakpoint: 576,
				options: {
					chart: {
						// width: 300,
						// height: 300
					},
					legend: {
						customLegendItems: labels,
						position: 'bottom',
						height: 700,
						floating: true
					}
				}
			}]
		}
	}

}