import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { iconCancel, iconSave, iconTrash } from "app/common/utils/icons.utils";

import { ProfileService } from 'app/services/profile.service';
import { EntityDefinition, FieldDefinition, FieldType, GroupDefinition } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles } from 'app/models/profile.models';
import { URLUtils } from 'app/services/url-utils';
import { cleanString, nameFieldsValidators } from 'app/common/models/utils';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'app/services/report.service';
import { ReportDef } from 'app/models/report.model';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { SelectListitem } from 'app/common/models/models';

@Component({
    selector: 'edit-entity-report',
    templateUrl: './edit-entity-report.modal.html'
})
export class EditEntityReportModalForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconFieldRemove = iconTrash;
	public entityForm: FormGroup = null;	
	public groups: GroupDefinition[] = [];
	public groupValidity: boolean[] = [];
	data: {
		entity: EntityDefinition
	};
	dataLoaded: boolean = false;
	private reportDef: ReportDef;
	public correlations: FormArray = null;
	public possibleValues: SelectListitem[] = [];
	ngOnInit() {
		let entity;
		if (this.data && this.data.entity) {
			entity = this.data.entity;
		}
		this._reportService.getReportFor(entity).subscribe((reportDef) => {
			if (reportDef && reportDef.length > 0)
				this.reportDef = reportDef[0];
			this.initForm(entity, this.reportDef);
			this.dataLoaded = true;
		})
		
		
	}

    constructor(
		private _reportService: ReportService,
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _translateService: TranslateService,
		private _userProfileService: ProfileService) {
			
    }

	
	isEdit = false;
	fieldsToScore: FieldDefinition[];
	fieldLabels: any = {};
	private makeFieldScore = (fieldDefinition: FieldDefinition, report: ReportDef) => {
		let fArrayData: any = [];		
		fieldDefinition.possibleValues.forEach((value) => {
			let v = 0;
			if (report && report.fieldsScore) {
				report.fieldsScore.forEach((fieldScore) => {
					fieldScore.scores.forEach((score) => {
						console.log(score.responseValue);
						
						if (score.responseValue == value) v = score.score;
					})
				})
			}			
			fArrayData.push(this._fb.group({
				responseValue: [value],
				score: [v]
			}));
		})
		return this._fb.array(fArrayData);
	}
	private makeFieldsScore = (entityDef: EntityDefinition, report: ReportDef) => {
		this.fieldsToScore = [];

		entityDef.baseInfo.forEach((field) => {
			if (field.fieldType == FieldType.SINGLE_SELECT /* || field.fieldType == FieldType.MULTIPLE_SELECT */) {
				this.fieldLabels[field.fieldId] = field.fieldLabel;
				this.fieldsToScore.push(field);
				let def: any = {};
				def.fieldId = [field.fieldId];
				let gr = this._fb.group({
					fieldId: [field.fieldId],
					scores: this.makeFieldScore(field, report)
				}) 
				this.formsScore.push(gr);
				this.possibleValues.push({
					id: field.fieldId,
					label: field.fieldLabel
				})
			}
		})
		entityDef.groups.forEach((group) => {
			group.fields.forEach((field) => {
				if (field.fieldType == FieldType.SINGLE_SELECT /* || field.fieldType == FieldType.MULTIPLE_SELECT */) {
					this.fieldLabels[field.fieldId] = field.fieldLabel;
					this.fieldsToScore.push(field);
					let gr = this._fb.group({
						fieldId: [field.fieldId],
						scores: this.makeFieldScore(field, report)
					}) 
					this.formsScore.push(gr);
					this.possibleValues.push({
						id: field.fieldId,
						label: field.fieldLabel
					})
				}
			})
		})
		
	}

	
	formsScore: FormGroup[] = [];
	private initForm = (entityDef: EntityDefinition, report: ReportDef) => {
		this.correlations = this._fb.array([]);

		if (!report)
			report = new ReportDef();
		report.entityId = entityDef.entityId;
		if (report.correlations) {
			report.correlations.forEach((c) => {
				this.addCorrelation(c);
			})
		}
		else {
			report.correlations = [];
		}

		if (report && report.id) this.isEdit = true;
		this.makeFieldsScore(entityDef,report);
	}

	addCorrelation = (value?: any) => {
		let additional = this._fb.group({
			label: [ null, CommonValidators.required],
			firstFieldId: [null, CommonValidators.required],
			lastFieldId: [null, CommonValidators.required]
		})

		if (value) additional.patchValue(value);
		this.correlations.push(additional);
	}
	removeCorrelation = (index: number) => {
		this.correlations.removeAt(index);
	}
	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}

    public save(certData?: boolean) {
		if (this.canSaveForm) {
			if (!this.reportDef) this.reportDef = new ReportDef();
			this.reportDef.entityId = this.data.entity.entityId;
			this.reportDef.fieldsScore = [];
			this.reportDef.correlations = this.correlations.value;
			this.formsScore.forEach((form) => {
				this.reportDef.fieldsScore.push(form.value);
			})
	
			this._reportService.saveOrUpdate(this.reportDef, this.data.entity).subscribe((r) => {
				this.activeModal.close(r);
			})
		}
    }

	get isValid(): boolean {
		let valid = true;
		this.formsScore.forEach((f) => valid = valid&&f.valid);
		return valid;
	}

	public get canSaveForm(): boolean {
		return this.isValid;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }
}


