import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition } from "app/models/entities.model";
import { ModalService } from "app/common/services/modal.service";
import { iconAdd, iconEdit, iconExcel, iconMoreUsers, iconTrash } from "app/common/utils/icons.utils";
import { CompanyService_CONFIG_DOMAIN, CompanyService_DOMAINS_CONFIG, EntityConfigurationService } from "app/services/entity-configuration.service";
import { ProfileService } from "app/services/profile.service";
import { Roles } from "app/models/profile.models";
import { ActivatedRoute, Router } from "@angular/router";
import { StoredObjectService } from "app/services/stored-object.service";
import { ToastService } from "app/common/services/toasts.service";


// /data-provider/dietquestionnaire-1731423757217/dietquestionnaire-1731423757217/root
@Component({
    selector: 'entity-object-provider',
    templateUrl: './entity-object-provider.component.html',
	styleUrls: ["entity-object-provider.component.scss"]
})
export class EntityObjectProviderPage implements OnInit {
	iconEdit = iconEdit;
	iconTrash = iconTrash;
	iconAdd = iconAdd;
	iconUserPermission = iconMoreUsers;
	iconExcel = iconExcel;
	rootAbstractEntity: EntityDefinition;
	currentEntityDef: EntityDefinition;
	historypath: any[] = [];
	parentIdChain;
	idWhitParent: any;
	objectIstance = null;
	valid = false;

	pageLoaded = false;
	@Input("entity") entity: EntityDefinition;
	@Input("canUpdate") canUpdate: boolean = false;
	@Input("isRoot") isRoot: boolean = false;
	@Output() public onSsaveEntity: EventEmitter<any> = new EventEmitter<any>();
	@Output() public requestDelete: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onCreateSheet: EventEmitter<any> = new EventEmitter<EntityDefinition>();
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _entityConfigurationService: EntityConfigurationService,
		private _storedObjectService: StoredObjectService,
		private _toastService: ToastService
	) {

	}

	canModify = true;
	public get canShareEntity() {
		return false;
	}
	dispatchSaveEvent = ($event) => {
		this.onSsaveEntity.emit($event);
	}

	get entityLoaded() {
		return this.currentEntityDef;
	}
	private searchEntityDefAndUpdateHistoryPath = (entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			this.historypath.unshift({
				abstractEntityId: entityId,
				rootAbstractEntity: this.rootAbstractEntity.entityId,
				entityName: entity.name
			})
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.searchEntityDefAndUpdateHistoryPath(children,entityId);
				if (hint) {
					this.historypath.unshift({
						abstractEntityId: entity.entityId,
						entityName: entity.name,
						rootAbstractEntity: this.rootAbstractEntity.entityId
					})
	
					found = hint;
				}
			})	
		}
		return found;
	}

	ngOnInit(): void {
		//:rootAbstractEntityId/:abstractEntityId/:parentIdChain
		this._activatedRoute.paramMap.subscribe(qp => {
			console.log("this._activatedRoute.paramMap.", qp);
			
			if (qp && qp.get("rootAbstractEntityId")) {
				this._entityConfigurationService.getByField("entityId",  qp.get("rootAbstractEntityId")).subscribe((result) => {
					this.rootAbstractEntity = result;
					
					if (qp.get("rootAbstractEntityId") == qp.get("abstractEntityId")) {
						this.currentEntityDef = result
					}
					else {
						this.currentEntityDef = this.searchEntityDefAndUpdateHistoryPath(this.rootAbstractEntity, qp.get("abstractEntityId"));								
					}
					this.parentIdChain = qp.get("parentIdChain");
					this.idWhitParent = this.parentIdChain + "." + qp.get("id");
					let ids = (""+this.parentIdChain).split(".");
					this.historypath = this.historypath.map((element, index) => {
						element["parentIdChain"] = ids.slice(0, index+1).join(".");
						element["objectId"] = ids[index+1];
						return element;
					})
					console.log("history: ", this.historypath);
					if (this.historypath.length > 0) {
						this.historypath.pop();
					}

					console.log("rootAbstractEntityId", this.rootAbstractEntity);
					console.log("currentEntityDef", this.currentEntityDef);
					
						this.pageLoaded = true;
					
				})
			}

		});
	}

	onObjectUpdate = (update) => {
		if (!this.objectIstance) this.objectIstance={};
		Object.assign(this.objectIstance, update);
	}
	objectValid = (valid) => {
		this.valid = valid;
	}

	saveObject = () => {
		if (this.valid && this.objectIstance) {
			// if (!this.objectIstance) this.objectIstance={};
			this.objectIstance.parentId = this.parentIdChain;
			this.objectIstance.entitiDefId = this.currentEntityDef.entityId;
			this.objectIstance.rootEntityDefId = this.rootAbstractEntity.entityId;
			this._storedObjectService.saveWithouthAuth(this.currentEntityDef,this.objectIstance).subscribe((newObject) => {
				this.objectIstance = newObject;
				this._toastService.showSuccess("I dati sono stati salvati.");

				const queryParams = {};
				this._router.navigate(["/data-provider"], { queryParams: queryParams });
			})
		}
	}

	// editEntity = () => {
	// 	let modalOption: ModalOptions<EntityDefinition> = {
	// 		size: 'xl',
	// 		callback: (res) => {
	// 			if (res) {
	// 				Object.assign(this.entity, res);
	// 				this.onSsaveEntity.emit(this.entity);
	// 			}
	// 		}
	// 	}
	// 	let data = {
	// 		entity: this.entity,
	// 	}
	// 	this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	// }

	// editPermission = () => {
	// 	let modalOption: ModalOptions<EntityDefinition> = {
	// 		size: 'xl',
	// 		callback: (res) => {
	// 			if (res) {
	// 				Object.assign(this.entity, res);
	// 				this.onSsaveEntity.emit(this.entity);
	// 			}
	// 		}
	// 	}
	// 	let data = {
	// 		entityDomain: CompanyService_DOMAINS_CONFIG + "/" + CompanyService_CONFIG_DOMAIN,
	// 		entityId:  this.entity.entityId,
	// 		entityName:  this.entity.name

	// 	}
	// 	this._modalService.show(AbstractEntityPermissionModalForm, data, modalOption);
		
	// }
	// addChild = () => {
	// 	let modalOption: ModalOptions<EntityDefinition> = {
	// 		size: 'xl',
	// 		callback: (res) => {
	// 			if (res) {
	// 				console.log("result: ", res);
	// 				if (!this.entity.entityChilds) this.entity.entityChilds = [];
	// 				res.entityId = this.entity.entityId + "." + res.entityId;
	// 				this.entity.entityChilds.push(res);
	// 				this.onSsaveEntity.emit(this.entity);
	// 			}
	// 		}
	// 	}
	// 	let data = {
	// 		entity: null,
	// 	}
	// 	this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	// }

	// delete = () => {
	// 	let modalOption: ModalOptions<boolean> = {
	// 		size: 'xl',
	// 		callback: (res) => {
	// 			if (res) {
	// 				this.requestDelete.emit(this.entity);
	// 			}
	// 		}
	// 	}
	// 	let data = {
	// 		entity: null,
	// 	}
	// 	this._modalService.showConfirm("Elimina entità","Confermi di eliminare l'entità " + this.entity.name + "?", modalOption);
	// }
}